import Typography from "@mui/material/Typography";
import MuiDialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MuiDialog from "@mui/material/Dialog";
import MuiDialogContent from "@mui/material/DialogContent";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useMemo } from "react";
import Box from "@mui/material/Box";
import { ReactComponent as DialogIcon } from "./dialog-icon.svg";

export const DialogHeader = ({
  children,
  onClose,
  isBack,
  onBack,
  showCloseIcon = true,
}) => {
  return (
    <MuiDialogTitle
      sx={{
        display: "flex",
        alignItems: "center",
        columnGap: 2,
      }}
      as="div"
    >
      {isBack && (
        <IconButton size="small" color="primary" onClick={onBack}>
          <ArrowBackIcon color="inherit" />
        </IconButton>
      )}
      <Typography variant="h3" flexGrow={1}>
        {children}
      </Typography>
      {showCloseIcon && (
        <IconButton size="small" color="grey" onClick={onClose}>
          <CloseIcon color="inherit" />
        </IconButton>
      )}
    </MuiDialogTitle>
  );
};

export const DialogCenteredHeader = ({
  children,
  onClose,
  icon = <DialogIcon />,
  showIcon,
}) => {
  return (
    <MuiDialogTitle
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        columnGap: 2,
      }}
      as="div"
    >
      <IconButton
        size="small"
        color="grey"
        onClick={onClose}
        sx={{ alignSelf: "flex-end" }}
      >
        <CloseIcon color="inherit" />
      </IconButton>
      {showIcon && <Box mb={8}>{icon}</Box>}

      <Typography variant="h3" textAlign="center">
        {children}
      </Typography>
    </MuiDialogTitle>
  );
};

export const DialogContent = ({
  sx,
  applyMaxHeight = false,
  applyHeight = false,
  componentRef,
  ...props
}) => {
  const sxProps = useMemo(() => {
    const config = { ...sx };
    if (applyMaxHeight) {
      config.maxHeight = {
        xs: "50vh",
        md: "70vh",
      };
    }
    if (applyHeight) {
      config.height = {
        xs: "50vh",
        md: "70vh",
      };
    }
    return config;
  }, [applyHeight, applyMaxHeight, sx]);
  return <MuiDialogContent {...props} sx={sxProps} ref={componentRef} />;
};

export const Dialog = ({ PaperProps = {}, ...props }) => {
  const sxPaperProps = useMemo(() => {
    return { ...PaperProps, sx: { ...PaperProps?.sx, borderRadius: 4 } };
  }, [PaperProps]);
  return <MuiDialog {...props} PaperProps={sxPaperProps} />;
};

Dialog.Header = DialogHeader;
Dialog.CenteredHeader = DialogCenteredHeader;
Dialog.Content = DialogContent;
