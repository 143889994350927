import { useCallback, useState } from "react";

const useToggleModal = (initial = false) => {
  const [open, setOpen] = useState(initial);

  const openModal = useCallback(() => {
    setOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setOpen(false);
  }, []);

  const toggleModal = useCallback(() => {
    setOpen((open) => !open);
  }, []);

  return {
    open,
    openModal,
    closeModal,
    toggleModal,
  };
};

export default useToggleModal;
