import React from "react";
import { Navigate } from "react-router-dom";
import OverlayLoader from "@shared-ui/components/overlay-loader";
import { useGetLogoAndThemeConfig } from "@client-services/services/singleton";
import { useAuth } from "@shared-features-auth/auth-context";

function PrivateRouteGuard({ children }) {
  const { isLoading } = useGetLogoAndThemeConfig();
  const { isAuthenticated, isInitialised } = useAuth();

  if (!isInitialised || isLoading) {
    return <OverlayLoader />;
  }

  if (isInitialised && !isAuthenticated) {
    return <Navigate to="/auth/sign-in" />;
  }

  return <>{children}</>;
}

export default PrivateRouteGuard;
