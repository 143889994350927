import get from "lodash/get";
import { formatDate } from "@shared-utils/time";
import map from "lodash/map";
import forEach from "lodash/forEach";

export const groupDateByDate = (data) => {
  const dataMap = {};
  forEach(data, (item) => {
    const dateKey = formatDate(item.date, "yyyy-dd-MM");
    const oldItem = get(dataMap, dateKey, {});
    dataMap[dateKey] = {
      ...oldItem,
      totalCommission: (oldItem.totalCommission || 0) + item.commission,
    };
  });
  return dataMap;
};

export const mergeDatesWithEarnings = (dates, earningsGroupByDate) => {
  return map(dates, (item) => {
    const { date, dayMonth } = item || {};
    const { totalCommission } = earningsGroupByDate[date] || {};
    return {
      name: dayMonth,
      totalCommission: totalCommission || 0,
    };
  });
};
