import Alert from "@mui/material/Alert";
import React from "react";
import { useGlobalConfigContext } from "@shared-domain/context/global-context";

const HighlightedAlert = ({ children, sx, ...rest }) => {
  const { themeHighlightedColor } = useGlobalConfigContext();

  const colorProps = themeHighlightedColor
    ? {
        bgcolor: themeHighlightedColor,
      }
    : {};

  return (
    <Alert
      {...rest}
      severity="success"
      sx={{ ...sx, ...colorProps }}
      icon={false}
    >
      {children}
    </Alert>
  );
};

export default HighlightedAlert;
