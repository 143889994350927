import { useCallback, useMemo, useState } from "react";
import { endOfMonth, startOfMonth } from "date-fns";
import { generateMonthDates, isValidDate } from "@shared-utils/time";

const useLineChartPickerDates = () => {
  const [date, setDate] = useState(new Date());

  const startDate = startOfMonth(new Date(date)).toISOString();
  const endDate = endOfMonth(new Date(date)).toISOString();

  const dates = useMemo(
    () => generateMonthDates(startDate, endDate),
    [endDate, startDate],
  );

  const onChangeDate = useCallback((value) => {
    if (isValidDate(value)) {
      setDate(value);
    }
  }, []);

  return {
    date,
    onChangeDate,
    startDate,
    endDate,
    dates,
  };
};

export default useLineChartPickerDates;
