import React from "react";
import styled from "@emotion/styled";
import { Menu as MenuIcon } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import MuiIconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import MuiAppBar from "@mui/material/AppBar";
import { Link } from "react-router-dom";
import { useGetMe } from "@client-services/services/user";
import { useLogos } from "@client-services/services/singleton";

const AppBar = styled(MuiAppBar)`
  background: white;
  color: ${(props) => props.theme.palette.grey.main};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Logo = styled.img`
  height: 40px;
  display: block;
  margin: auto;
  img {
    object-fit: contain;
  }
`;

const Navbar = ({ onDrawerToggle }) => {
  const { data: user = {} } = useGetMe();
  const { authLogo } = useLogos();
  const { profileImage } = user || {};
  return (
    <AppBar position="sticky" elevation={2}>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <Toolbar>
          <Box
            display="grid"
            gridTemplateColumns="repeat(3,1fr)"
            alignItems="center"
            width="100%"
          >
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={onDrawerToggle}
              size="large"
              sx={{
                justifySelf: "flex-start",
              }}
            >
              <MenuIcon />
            </IconButton>
            <Logo alt="" src={authLogo} sx={{ justifySelf: "center" }} />
            <Link
              to="/"
              style={{ justifySelf: "flex-end", textDecoration: "none" }}
            >
              <Avatar
                alt=""
                src={profileImage}
                sx={{
                  width: "32px",
                  height: "32px",
                }}
              />
            </Link>
          </Box>
        </Toolbar>
      </Box>
    </AppBar>
  );
};

export default Navbar;
