import React, { lazy, Suspense } from "react";

import AuthLayout from "./layouts/auth-layout";
import PrivateLayout from "./layouts/private-layout";

import PrivateRouteGuard from "./guards/private-route-guard";

import Page404 from "../pages/auth/Page404";
import PublicRouteGuard from "./guards/public-route-guard";
import OverlayLoader from "@shared-ui/components/overlay-loader";
import Box from "@mui/material/Box";
import PageTitle from "@shared-ui/components/page-title";
import EarningPage from "../pages/admin/earning";

const SignInPage = lazy(() => import("../pages/auth/sign-in"));
const SignInVerifyOtpPage = lazy(
  () => import("../pages/auth/sign-in-verify-otp"),
);
const SignUpPage = lazy(() => import("../pages/auth/sign-up"));
const SignUpSuccessPage = lazy(() => import("../pages/auth/sign-up-success"));

const SuspendedAccountPage = lazy(
  () => import("../pages/auth/suspend-account-page"),
);

const EncountersPage = lazy(() => import("../pages/shared/list-encounters"));
const NewEncounterCategoriesPage = lazy(
  () => import("../pages/patient/create-encounter/new-encounter-categories"),
);
const NewEncounterSubCategoriesPage = lazy(
  () =>
    import("../pages/patient/create-encounter/new-encounter-sub-categories"),
);
const EncounterQuestionsPage = lazy(
  () => import("../pages/patient/create-encounter/encounter-questions"),
);

const EncounterPaymentPage = lazy(
  () => import("../pages/patient/create-encounter/encounter-payment"),
);

const EncounterPaymentSuccessPage = lazy(
  () => import("../pages/patient/create-encounter/encounter-payment-success"),
);

const WaitingRoomPage = lazy(() => import("../pages/provider/waiting-room"));

const SupportPage = lazy(() => import("../pages/patient/support"));

const DashboardPage = lazy(() => import("../pages/shared/dashboard"));
const ProfilePage = lazy(() => import("../pages/shared/profile"));

const RapidResponsePage = lazy(
  () => import("../pages/provider/rapid-response"),
);

//admin tools
const AdminToolsPage = lazy(() => import("../pages/shared/settings"));
const DoctorAdministrationPage = lazy(
  () => import("../pages/admin/doctor-administration"),
);

const PracticeCustomisationPage = lazy(
  () => import("../pages/admin/practice-customisation"),
);

const EncounterCategoriesPage = lazy(
  () => import("../pages/admin/encounter-editor/encounter-categories"),
);

const CreateEncounterCategoryPage = lazy(
  () => import("../pages/admin/encounter-editor/create-encounter-category"),
);

const EditEncounterCategoryPage = lazy(
  () => import("../pages/admin/encounter-editor/edit-encounter-category"),
);

const EncounterSubcategoriesPage = lazy(
  () => import("../pages/admin/encounter-editor/encounter-subcategories"),
);

const CreateEncounterSubcategoryPage = lazy(
  () => import("../pages/admin/encounter-editor/create-encounter-subcategory"),
);

const EditEncounterSubcategoryPage = lazy(
  () => import("../pages/admin/encounter-editor/edit-encounter-subcategory"),
);

const CreateDoctorPage = lazy(() => import("../pages/admin/doctor/create"));
const EditDoctorPage = lazy(() => import("../pages/admin/doctor/edit"));
const ExpiringCredentialsPage = lazy(
  () => import("../pages/admin/expiring-credentials"),
);

const AdminAlertsPage = lazy(() => import("../pages/admin/admin-alerts"));
const AlertsPage = lazy(() => import("../pages/shared/alerts"));
const DoctorsPayrollPage = lazy(
  () => import("../pages/admin/doctors-payroll-page"),
);

const FaxStatusPage = lazy(() => import("../pages/admin/fax-status-page"));
const PatientSearchPage = lazy(() => import("../pages/admin/patient-search"));

const GettingStartedPage = lazy(
  () => import("../pages/shared/getting-started"),
);

const LogoutPage = lazy(() => import("../pages/shared/logout"));

export const buildRoutes = () => {
  return [
    {
      path: "/",
      element: (
        <PrivateRouteGuard>
          <PrivateLayout />
        </PrivateRouteGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <DashboardPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "auth",
      element: (
        <PublicRouteGuard>
          <AuthLayout />
        </PublicRouteGuard>
      ),
      children: [
        {
          path: "sign-in",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <SignInPage />
            </Suspense>
          ),
        },
        {
          path: "sign-in/verify-otp",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <SignInVerifyOtpPage />
            </Suspense>
          ),
        },
        {
          path: "sign-up",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <SignUpPage />
            </Suspense>
          ),
        },
        {
          path: "sign-up/success",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <SignUpSuccessPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "account",
      element: <AuthLayout />,
      children: [
        {
          path: "suspended",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <SuspendedAccountPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/profile",
      element: (
        <PrivateRouteGuard>
          <PrivateLayout />
        </PrivateRouteGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <ProfilePage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/dashboard",
      element: (
        <PrivateRouteGuard>
          <PrivateLayout />
        </PrivateRouteGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <DashboardPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/encounters",
      element: (
        <PrivateRouteGuard>
          <PrivateLayout />
        </PrivateRouteGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <EncountersPage />
            </Suspense>
          ),
        },
        {
          path: "new",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <NewEncounterCategoriesPage />
            </Suspense>
          ),
        },
        {
          path: "new/:category",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <NewEncounterSubCategoriesPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/waiting-room",
      element: (
        <PrivateRouteGuard>
          <PrivateLayout />
        </PrivateRouteGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <WaitingRoomPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/expiring-credentials",
      element: (
        <PrivateRouteGuard>
          <PrivateLayout />
        </PrivateRouteGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <ExpiringCredentialsPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/admin-alerts",
      element: (
        <PrivateRouteGuard>
          <PrivateLayout />
        </PrivateRouteGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <AdminAlertsPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/alerts",
      element: (
        <PrivateRouteGuard>
          <PrivateLayout />
        </PrivateRouteGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <AlertsPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/doctors-payroll",
      element: (
        <PrivateRouteGuard>
          <PrivateLayout />
        </PrivateRouteGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <DoctorsPayrollPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/fax-status",
      element: (
        <PrivateRouteGuard>
          <PrivateLayout />
        </PrivateRouteGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <FaxStatusPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/patient-search",
      element: (
        <PrivateRouteGuard>
          <PrivateLayout />
        </PrivateRouteGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <PatientSearchPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/getting-started",
      element: (
        <PrivateRouteGuard>
          <PrivateLayout />
        </PrivateRouteGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <GettingStartedPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/new-encounter",
      element: (
        <PrivateRouteGuard>
          <PrivateLayout />
        </PrivateRouteGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <NewEncounterCategoriesPage />
            </Suspense>
          ),
        },
        {
          path: ":category",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <NewEncounterSubCategoriesPage />
            </Suspense>
          ),
        },
        {
          path: ":category/:subcategory/questions",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <EncounterQuestionsPage />
            </Suspense>
          ),
        },
        {
          path: ":category/:subcategory/payment",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <EncounterPaymentPage />
            </Suspense>
          ),
        },
        {
          path: ":category/:subcategory/payment/success",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <EncounterPaymentSuccessPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "settings",
      element: (
        <PrivateRouteGuard>
          <PrivateLayout />
        </PrivateRouteGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <AdminToolsPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "doctor-administration",
      element: (
        <PrivateRouteGuard>
          <PrivateLayout />
        </PrivateRouteGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <DoctorAdministrationPage />
            </Suspense>
          ),
        },
        {
          path: "create",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <CreateDoctorPage />
            </Suspense>
          ),
        },
        {
          path: "edit/:id",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <EditDoctorPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "logout",
      element: (
        <PrivateRouteGuard>
          <PrivateLayout />
        </PrivateRouteGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <LogoutPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/rapid-responses",
      element: (
        <PrivateRouteGuard>
          <PrivateLayout />
        </PrivateRouteGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <RapidResponsePage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/practice-customisation",
      element: (
        <PrivateRouteGuard>
          <PrivateLayout />
        </PrivateRouteGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <PracticeCustomisationPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/encounter-editor",
      element: (
        <PrivateRouteGuard>
          <PrivateLayout />
        </PrivateRouteGuard>
      ),
      children: [
        {
          path: "categories",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <EncounterCategoriesPage />
            </Suspense>
          ),
        },
        {
          path: "categories/create",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <CreateEncounterCategoryPage />
            </Suspense>
          ),
        },
        {
          path: "categories/:id/edit",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <EditEncounterCategoryPage />
            </Suspense>
          ),
        },
        {
          path: "categories/:category/subcategories",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <EncounterSubcategoriesPage />
            </Suspense>
          ),
        },
        {
          path: "categories/:category/subcategories/create",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <CreateEncounterSubcategoryPage />
            </Suspense>
          ),
        },
        {
          path: "categories/:category/subcategories/:subcategory/edit",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <EditEncounterSubcategoryPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/earnings",
      element: (
        <PrivateRouteGuard>
          <PrivateLayout />
        </PrivateRouteGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <EarningPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/membership",
      element: (
        <PrivateRouteGuard>
          <PrivateLayout />
        </PrivateRouteGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <Box p={4}>
                <PageTitle>Membership</PageTitle>
              </Box>
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/weight",
      element: (
        <PrivateRouteGuard>
          <PrivateLayout />
        </PrivateRouteGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <Box p={4}>
                <PageTitle>weight in</PageTitle>
              </Box>
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/support",
      element: (
        <PrivateRouteGuard>
          <PrivateLayout />
        </PrivateRouteGuard>
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<OverlayLoader />}>
              <SupportPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "*",
      element: <AuthLayout />,
      children: [
        {
          path: "*",
          element: <Page404 />,
        },
      ],
    },
  ];
};
