import { darken, lighten } from "polished";

const pallet = (baseColor) => {
  return {
    50: lighten(0.34, baseColor),
    200: lighten(0.2, baseColor),
    300: baseColor,
    500: darken(0.5, baseColor),
  };
};

export const getTheme = (themePrimaryColor) => {
  const primary = pallet(themePrimaryColor || "#f36e4c");
  const secondary = {
    50: "#e9eef6",
    200: "#4c6fb3",
    300: "#2751a3",
    500: "#183163",
  };
  const success = {
    50: "#e7f7f0",
    200: "#36bf84",
    300: "#0db26b",
    500: "#086d41",
  };
  return {
    name: "default",
    palette: {
      mode: "light",
      primary: {
        lighter: primary["50"],
        light: primary["200"],
        main: primary["300"],
        dark: primary["500"],
        contrastText: "#FFF",
      },
      secondary: {
        lighter: secondary["50"],
        light: secondary["200"],
        main: secondary["300"],
        dark: secondary["500"],
        contrastText: "#FFF",
      },
      success: {
        lighter: success["50"],
        light: success["200"],
        main: success["300"],
        dark: success["500"],
        contrastText: "#FFF",
      },
      grey: {
        main: "#626366",
        light: "#AEB1BB",
        lighter: "#AEB1BB",
        dark: "#AEB1BB",
        contrastText: "#FFF",
      },
      yellow: {
        main: "#FAFAE1",
        lighter: "#FAFAE1",
        dark: "#FAFAE1",
        contrastText: "#FFF",
      },
      bg: {
        main: "#F8F9FB",
        contrastText: "#FFF",
      },
      border: {
        main: "#E6E8EB",
      },
      background: {
        default: "#FFF",
        paper: "#FFF",
      },
    },
  };
};
