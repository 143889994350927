import { createContext, useContext, useMemo } from "react";

export const GlobalConfigContext = createContext({
  providerType: "",
  practiceName: "",
  themeHighlightedColor: "",
});

export const GlobalConfigProvider = ({
  providerType,
  practiceName,
  themeHighlightedColor,
  uploadFileOrBlob,
  children,
  env,
}) => {
  const value = useMemo(
    () => ({
      providerType,
      practiceName,
      themeHighlightedColor,
      uploadFileOrBlob,
      env,
    }),
    [env, practiceName, providerType, themeHighlightedColor, uploadFileOrBlob],
  );
  return (
    <GlobalConfigContext.Provider value={value}>
      {children}
    </GlobalConfigContext.Provider>
  );
};

export const useGlobalConfigContext = () => {
  const {
    practiceName,
    providerType,
    themeHighlightedColor,
    uploadFileOrBlob,
    env,
  } = useContext(GlobalConfigContext);
  return {
    practiceName,
    providerType,
    themeHighlightedColor,
    uploadFileOrBlob,
    env: {
      FILE_MAX_SIZE_IN_MB: env.FILE_MAX_SIZE_IN_MB,
    },
  };
};
