import i18n from "i18next";
import {
  initReactI18next,
  useTranslation as useBaseTranslation,
} from "react-i18next";
import capitalize from "lodash/capitalize";
import { translation } from "@shared-ui/localisation/translation";
import { useGlobalConfigContext } from "@shared-domain/context/global-context";

export const LOCALISATION_NAMESPACES = {
  DOCTOR: "doctor",
  PROVIDER: "provider",
};

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation,
    },
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export const useTranslation = () => {
  const { practiceName, providerType } = useGlobalConfigContext();
  const translation = useBaseTranslation();
  return {
    ...translation,
    t: (key, data) =>
      translation.t(key, {
        ...data,
        providerType: capitalize(providerType),
        practiceName,
      }),
  };
};
