import React from "react";
import Page from "@shared-ui/components/page";
import PageTitle from "@shared-ui/components/page-title";
import EarningsChart from "./earnings-chart";
import EarningSummary from "./earning-summary";
import HighlightedAlert from "@shared-domain/components/HighlightedAlert";

const EarningPage = () => {
  return (
    <Page>
      <PageTitle>Earnings</PageTitle>
      <HighlightedAlert>
        Please note that all deposits are processed on the 10th of each month
      </HighlightedAlert>
      <EarningSummary />
      <EarningsChart />
    </Page>
  );
};

export default EarningPage;
