import "@mui/lab/themeAugmentation";

import { createTheme as createMuiTheme } from "@mui/material/styles";
import { getTheme } from "./variants";
import typography from "./typography";
import breakpoints from "./breakpoints";
import components from "./components";
import shadows from "./shadows";

const createTheme = (themePrimaryColor) => {
  const themeConfig = getTheme(themePrimaryColor);
  return createMuiTheme(
    {
      spacing: 4,
      breakpoints: breakpoints,
      components: components,
      typography: typography,
      shadows: shadows,
      palette: themeConfig.palette,
      cssVariables: true,
    },
    {
      name: themeConfig.name,
      header: themeConfig.header,
      footer: themeConfig.footer,
      sidebar: themeConfig.sidebar,
    },
  );
};

export default createTheme;
