export const USER_CACHE_KEYS = {
  ME: "me",
  USER: "user",
  DOCTORS: "doctors",
  EXPIRY_CREDENTIALS: "expiry-credentials",
};

export const WAITING_ROOM_CACHE_KEYS = {
  LIST: "waiting-room-list",
};

export const RAPID_RESPONSE_CACHE_KEYS = {
  LIST: "rapid-response-list",
};

export const SINGLETON_CACHE_KEYS = {
  PROMPTS: "singleton-prompts",
  WEIGHT_LOSS_MEDICINE: "singleton-weight-loss-medicine",
  LOGOS_AND_THEME_CONFIG: "singleton-logos-and-theme-config",
  PRACTICE_INFORMATION: "singleton-practice-information",
  ORDERS_AND_ACTIONS_CONFIG: "singleton-orders-and-actions-config",
  PRICE_CONFIG: "singleton-price-config",
  ORDER_TEMPLATES_CONFIG: "singleton-order-templates-config",
};

export const EARNING_CACHE_KEYS = {
  EARNINGS_SUMMARY: "earnings-summary",
  EARNINGS_DETAILS: "earnings-details",
};

export const PAYROLL_CACHE_KEYS = {
  PAYROLL_SUMMARY: "payroll-summary",
};

export const PRICE_CACHE_KEYS = {
  GET_ALL: "prices-get-all",
};

export const ENCOUNTER_NOTE_CACHE_KEYS = {
  LIST: "encounter-note",
};

export const DASHBOARD_CACHE_KEYS = {
  ADMIN_DASHBOARD_SUMMARY: "dashboard-admin-summary",
  DOCTOR_DASHBOARD_SUMMARY: "dashboard-doctor-summary",
  PATIENT_DASHBOARD_SUMMARY: "dashboard-patient-summary",
  PATIENT_SEEN_DOCTORS: "dashboard-patient-seen-doctors",
  DASHBOARD_ENCOUNTERS_SUMMARY: "dashboard-encounters-summary",
};

export const PATIENT_CACHE_KEYS = {
  PATIENT: "patient",
};

export const ALLERGY_CACHE_KEYS = {
  USER_ALLERGIES: "user-allergies",
  PATIENT_ALLERGIES: "patient-allergies",
};

export const ENCOUNTER_CACHE_KEYS = {
  GET_ENCOUNTER: "encounter",
  GET_ENCOUNTER_CHAT: "encounter-chat",
  GET_WEIGHT_LOSS_FAXES: "encounter-weight-loss-faxes",
  SEARCH_PATIENT_ENCOUNTERS: "encounter-search-patient-encounters",
};

export const RCOPIA_CACHE_KEYS = {
  GET_ALLERGIES: "get-allergies",
  GET_MEDICATIONS: "get-medications",
  GET_NOTIFICATIONS_COUNT: "get-notifications-count",
};

export const STATE_CACHE_KEYS = {
  ALL: "state-all",
};
