import React from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { DatePicker } from "@mui/x-date-pickers";
import map from "lodash/map";
import size from "lodash/size";

const ChartSection = styled(Box)`
  font-size: 12px;
  border: 1px solid ${(props) => props.theme.palette.border.main};

  .xAxis {
    line {
      stroke: #e6e8eb !important;
    }
  }

  .yAxis {
    line {
      display: none;
    }
  }

  .recharts-cartesian-axis-tick {
    line {
      display: none;
    }

    text {
      fill: #626366 !important;
    }
  }
`;

const error = console.error;
console.error = (...args) => {
  if (/defaultProps/.test(args[0])) return;
  if (/Using kebab-case/.test(args[0])) return;
  error(...args);
};

const LineChart = ({
  title,
  data = [],
  monthlyPicker = false,
  date,
  onChangeDate,
  config,
  unit = "",
}) => {
  return (
    <ChartSection display="grid" gap={4} borderRadius={4} p={4}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Typography variant="h6">{title}</Typography>
        {monthlyPicker && (
          <DatePicker
            openTo="month"
            views={["year", "month"]}
            label="Year and Month"
            value={date}
            onChange={onChangeDate}
            sx={{ maxWidth: 300 }}
            slotProps={{ textField: { error: false } }}
          />
        )}
      </Box>
      <ResponsiveContainer width="100%" height={200}>
        <AreaChart
          data={data}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <CartesianGrid horizontal vertical={false} stroke="#E6E8EB" />
          <XAxis dataKey="name" color="green" />
          <YAxis />
          <Tooltip />
          {map(config, (item) => {
            return (
              <Area
                key={item.dataKey}
                type="monotone"
                stroke={item.stroke || "#f36e4c"}
                fill={item.fill || "#F36E4C29"}
                strokeWidth={2}
                name={item.name}
                dataKey={item.dataKey}
                unit={unit}
              />
            );
          })}
          {size(config) > 1 && <Legend />}
        </AreaChart>
      </ResponsiveContainer>
    </ChartSection>
  );
};

export default LineChart;
