import React from "react";
import { Navigate } from "react-router-dom";
import Loader from "@shared-ui/components/loader";
import { useAuth } from "@shared-features-auth/auth-context";

function PublicRouteGuard({ children }) {
  const { isAuthenticated, isInitialised } = useAuth();
  if (!isInitialised) {
    return <Loader />;
  }
  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  return <>{children}</>;
}

export default PublicRouteGuard;
