import React from "react";

import Avatar from "@mui/material/Avatar";
import { useGetMe } from "@client-services/services/user";
import SidebarNavListItem from "./SidebarNavListItem";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { ReactComponent as SupportIcon } from "./icons/support.svg";

const SidebarFooter = ({ onClose }) => {
  const { data, isLoading } = useGetMe();
  const displayName = data?.displayName;
  const profileImage = data?.profileImage;

  if (isLoading) {
    return (
      <Box
        p={4}
        height="45px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        mb={4}
      >
        <CircularProgress size={20} color="secondary" />
      </Box>
    );
  }
  return (
    <>
      <SidebarNavListItem
        href="/support"
        Icon={<SupportIcon />}
        title="Support"
        onClose={onClose}
        closeOnSelect
      />
      <SidebarNavListItem
        href="/settings"
        title={displayName}
        onClose={onClose}
        closeOnSelect
      >
        <Avatar
          sx={{ width: 24, height: 24 }}
          alt={displayName}
          src={profileImage}
        />
      </SidebarNavListItem>
    </>
  );
};

export default SidebarFooter;
