import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import noop from "lodash/noop";
import { useQueryClient } from "@tanstack/react-query";
import { TokenUtils } from "@shared-utils/storage";

const AuthContext = createContext(null);

function AuthProvider({ children, getMeApi }) {
  const queryClient = useQueryClient();
  const [user, setUser] = useState(false);
  const [isInitialised, setIsInitialised] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const verifyUserAndNavigate = useCallback(async () => {
    await getMeApi().then((response) => {
      const user = response.data.data ?? {};
      setUser({
        id: user._id,
        email: user.email,
        role: user.role,
      });
      setIsAuthenticated(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const init = async () => {
      const accessToken = TokenUtils.getAccessToken();
      if (accessToken) {
        await verifyUserAndNavigate();
      }
    };
    init()
      .catch(noop)
      .finally(() => {
        setIsInitialised(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSignOut = useCallback(async () => {
    setIsAuthenticated(false);
    TokenUtils.removeAuthTokens();
    queryClient.clear();
  }, [queryClient]);

  const onSignInSuccess = useCallback(
    async (user) => {
      TokenUtils.persistAuthTokens(user.accessToken, user.refreshToken);
      await verifyUserAndNavigate();
    },
    [verifyUserAndNavigate],
  );

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isInitialised,
        user,
        onSignOut,
        onSignInSuccess,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context)
    throw new Error("AuthContext must be placed within AuthProvider");

  const {
    isAuthenticated,
    isInitialised,
    user,
    onSignOut,
    onSignInSuccess,
    ...rest
  } = context;

  return {
    isAuthenticated,
    isInitialised,
    user: {
      id: user.id,
      email: user.email,
      role: user.role,
    },
    onSignOut,
    onSignInSuccess,
    ...rest,
  };
};

const useCurrentUserId = () => {
  const { user } = useAuth();
  return user.id;
};

export { AuthContext, AuthProvider, useAuth, useCurrentUserId };
