import React from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";

import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { lighten } from "polished";

const Item = styled(ListItemButton)`
  padding: 12px;
  color: black;
  border-radius: 8px;
  margin-bottom: 4px;

  svg {
    color: black;
    font-size: 20px;
    width: 20px;
    height: 20px;
  }

  &:hover {
    background-color: ${(props) =>
      lighten(0.2, props.theme.palette.primary.main)};
  }

  span.MuiBadge-badge {
    color: white;
    background-color: ${(props) => props.theme.palette.primary.main};
  }

  &.active {
    background-color: ${(props) => props.theme.palette.primary.main};

    svg,
    span {
      color: white;
    }

    span.MuiBadge-badge {
      color: white;
      background-color: ${(props) => props.theme.palette.secondary.main};
    }
  }
`;

const Title = styled(ListItemText)`
  margin: 0;

  span {
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    padding: 0 ${(props) => props.theme.spacing(4)};
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const SidebarNavListItem = (props) => {
  const {
    title,
    href,
    depth = 0,
    Icon,
    onClose,
    onMenuItemPress,
    closeOnSelect,
  } = props;

  const Component = Item;
  const TitleComponent = Title;
  return (
    <Component
      depth={depth}
      component={NavLink}
      to={href}
      onClick={(event) => {
        onMenuItemPress?.(event);
        if (closeOnSelect) {
          onClose?.();
        }
      }}
    >
      {props.children}
      {Icon}
      <TitleComponent>{title}</TitleComponent>
    </Component>
  );
};

export default SidebarNavListItem;
