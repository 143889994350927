import axios from "axios";
import get from "lodash/get";
import set from "lodash/set";
import { TokenUtils } from "@shared-utils/storage";
import { ErrorCodes } from "./constants";

export default class ResponseInterceptors {
  constructor(baseUrl, queryClient) {
    this.failedQueue = [];
    this.baseUrl = baseUrl;
    this.queryClient = queryClient;
  }

  processQueue = (error, token) => {
    this.failedQueue.forEach((queuedRequest) => {
      if (error) {
        queuedRequest.reject(error);
      } else {
        queuedRequest.resolve(token);
      }
    });
    this.failedQueue = [];
  };

  handleSignOut = () => {
    TokenUtils.removeAuthTokens();
    this.queryClient.clear();
    window.location.href = "/auth/sign-in";
  };

  getRefreshAccessToken = async (refreshToken) => {
    return axios
      .post(`${this.baseURL}/auth/refresh_token`, { refreshToken })
      .then((response) => response?.data?.data ?? {});
  };

  onFulfilled = (config) => config;

  onRejected = async (error) => {
    const originalRequest = error.config;
    const httpStatus = error?.response?.status;
    const isRetriedRequest = get(originalRequest, "retry");

    const errorCode = error?.response?.data?.errorCode;
    if (errorCode === ErrorCodes.ACCOUNT_SUSPENDED) {
      this.handleSignOut();
    } else if (httpStatus === 401 && isRetriedRequest) {
      this.handleSignOut();
    } else if (httpStatus === 401 && !isRetriedRequest) {
      if (this.isRefreshingInProgress) {
        return new Promise((resolve, reject) =>
          this.failedQueue.push({ resolve, reject }),
        )
          .then((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return axios(originalRequest);
          })
          .catch((refreshingError) => refreshingError);
      }
      set(originalRequest, "retry", true);
      this.isRefreshingInProgress = true;

      return new Promise(async (resolve, reject) => {
        try {
          const oldRefreshToken = TokenUtils.getRefreshToken();
          if (!oldRefreshToken) {
            throw new Error("Refresh token is missing");
          }
          const response = await this.getRefreshAccessToken(oldRefreshToken);
          const updatedAccessToken = response.accessToken;
          const updatedRefreshToken = response.refreshToken;
          TokenUtils.persistAuthTokens(updatedAccessToken, updatedRefreshToken);
          originalRequest.headers.Authorization = TokenUtils.getBearerToken();
          this.processQueue(null, updatedAccessToken);
          resolve(axios(originalRequest));
        } catch (refreshTokenError) {
          this.processQueue(refreshTokenError, null);
          reject(refreshTokenError);
          this.handleSignOut();
        } finally {
          this.isRefreshingInProgress = false;
        }
      });
    }

    return Promise.reject(error);
  };
}
