import { buildApiInstances } from "../../api-instances";
import { CLIENT_ENV } from "@shared-utils/envs/client";
import { clientQueryClient } from "./client";

const { privateInstance, publicInstance } = buildApiInstances(
  CLIENT_ENV.API_URL,
  clientQueryClient,
);

const clientApis = {
  auth: {
    signIn: (data) => publicInstance.post("/auth/sign-in", data),
    signInVerifyOtp: (data) =>
      publicInstance.post("/auth/sign_in_verify_otp", data),
    signUp: (data) =>
      publicInstance
        .post("/auth/patient/sign-up", data)
        .then((response) => response.data.data ?? {}),
    getSuspensionDetails: (data) =>
      publicInstance.post("/auth/get_suspension_details", data),
  },
  payment: {
    config: (data) =>
      publicInstance
        .get("/config", data)
        .then((response) => response.data ?? {}),

    getPaymentConfig: (params) =>
      privateInstance.get("/payment/get_payment_config", { params }),
    processPayment: (data) =>
      privateInstance.post("/payment/process_payment", data),
  },
  encounterCategories: {
    getAll: () => privateInstance.get("/encounter-category/all"),
    get: (id) => privateInstance.get(`/encounter-category/${id}`),
    createCategory: (data) =>
      privateInstance.post("/encounter-category/create-category", data),
    editCategory: (data) =>
      privateInstance.post(`/encounter-category/edit-category`, data),
    deleteCategory: (data) =>
      privateInstance.post(`/encounter-category/delete-category`, data),
    createSubcategory: (data) =>
      privateInstance.post("/encounter-category/create-subcategory", data),
    editSubcategory: (data) =>
      privateInstance.post(`/encounter-category/edit-subcategory`, data),
    deleteSubcategory: (data) =>
      privateInstance.post(`/encounter-category/delete-subcategory`, data),
  },
  encounter: {
    get: (id) => privateInstance.get(`/encounter/${id}`),
    create: (data) => privateInstance.post("/encounter/create", data),
    close: (data) => privateInstance.post("/encounter/close", data),
    refund: (data) => privateInstance.post("/encounter/refund", data),
    reOpen: (data) => privateInstance.post("/encounter/re-open", data),
    getChat: (id) => privateInstance.get(`/encounter/${id}/chat`),
    searchPatientEncounters: (body) =>
      privateInstance.post("/encounter/search-patient-encounters", body),
    freeVideoCallPurchased: (body) =>
      privateInstance.post("/encounter/free_video_call_purchased", body),
    videoCallPurchased: (body) =>
      privateInstance.post("/encounter/video_call_purchased", body),
    sendMeetingLink: (body) =>
      privateInstance.post("/encounter/send_meeting_link", body),
  },
  fax: {
    sendWeightLossFax: (body) =>
      privateInstance.post("/fax/weight-loss-fax", body),
    getWeightLossFaxes: () => privateInstance.get("/fax/faxes/weight-loss-fax"),
  },
  encounterNote: {
    getPatientNotes: (data) =>
      privateInstance.post("/encounter-note/patient-encounter-notes", data),
    create: (data) => privateInstance.post("/encounter-note/create", data),
    discard: (data) => privateInstance.post("/encounter-note/discard", data),
    edit: (data) => privateInstance.put("/encounter-note", data),
  },
  waitingRoom: {
    list: (params) => privateInstance.get("/waiting-room/list", { params }),
    assignEncounter: (body) =>
      privateInstance.post("/waiting-room/assign-encounter", body),
  },
  patient: {
    list: (params) => privateInstance.get("/patient/list", { params }),
    updatePatientProfile: (data) =>
      privateInstance.put("/patient/update_patient_profile", data),
  },
  patientAllergies: {
    getAllergies: () => privateInstance.get("/patient_allergy/get_allergies"),
    getUserAllergies: (params) =>
      privateInstance.get("/patient_allergy/get_user_allergies", { params }),
    addAllergy: (data) =>
      privateInstance.post("/patient_allergy/add_allergy", data),
    changeAllergyStatus: (data) =>
      privateInstance.post("/patient_allergy/change_allergy_status", data),
    removeAllergy: (data) =>
      privateInstance.post("/patient_allergy/remove_allergy", data),
  },
  googlePlaces: {
    details: (params) => privateInstance.get("/place/details", { params }),
  },
  chat: {
    getChatToken: () => privateInstance.get("/chat/token"),
  },
  chatgpt: {
    getImagingICD10Codes: (params) =>
      privateInstance.get("/chatgpt/imaging-icd10-codes", { params }),

    getLabICD10Codes: (params) =>
      privateInstance.get("/chatgpt/lab-icd10-codes", { params }),

    getEncounterAiRespond: (body) =>
      privateInstance.post("/chatgpt/encounter-ai-respond", body),

    getEncounterAiSummary: (body) =>
      privateInstance.post("/chatgpt/encounter-ai-summary", body),
  },
  user: {
    me: () => privateInstance.get("/user/me"),
    get: (id) => privateInstance.get(`/user/${id}`),
  },
  doctor: {
    updateProfile: (data) =>
      privateInstance
        .put("/doctor/update-doctor-profile", data)
        .then((response) => response.data.data ?? {}),
    updateSignature: (data) =>
      privateInstance
        .put("/doctor/update-doctor-signature", data)
        .then((response) => response.data.data ?? {}),

    listDoctors: (params) => privateInstance.get("/doctor/list", { params }),
    expiringCredentials: (params) =>
      privateInstance.get("/doctor/expiring-credentials", { params }),
  },

  manageUsers: {
    createDoctor: (body) =>
      privateInstance.post("/manage-user/create-doctor", body),
    editDoctor: (body) =>
      privateInstance.post("/manage-user/edit-doctor", body),
    suspendUser: (body) =>
      privateInstance.put("/manage-user/suspend_user", body),
    activateUser: (body) =>
      privateInstance.put("/manage-user/activate_user", body),
  },

  rapidResponse: {
    getRapidResponse: (params) =>
      privateInstance.get("/rapid-response/get", { params }),
    createRapidResponse: (body) =>
      privateInstance.post("/rapid-response/create", body),
    editRapidResponse: (body) =>
      privateInstance.post("/rapid-response/edit", body),
    deleteRapidResponse: (body) =>
      privateInstance.post("/rapid-response/delete", body),
  },
  singleton: {
    getLogosAndThemConfig: () =>
      publicInstance.get("/singleton/get_logos_and_theme_config"),

    getPracticeInformation: () =>
      publicInstance.get("/singleton/get_practice_information"),

    getOrdersAndActionsConfig: () =>
      privateInstance.get("/singleton/get_orders_and_actions_config"),

    getPriceConfig: () => privateInstance.get("/singleton/get_price_config"),
    getOrderTemplatesConfig: () =>
      privateInstance.get("/singleton/get_orders_templates_config"),
    getPracticeConfig: () =>
      privateInstance.get("/singleton/get_practice_config"),
    updatePracticeConfig: (body) =>
      privateInstance.post("/singleton/update_practice_config", body),
  },
  alertSettings: {
    getAlertSettings: () => privateInstance.get("/alert-setting/get"),
    updateAlertSettings: (body) =>
      privateInstance.post("/alert-setting/update", body),
  },
  state: {
    getAllStates: () => publicInstance.get("/state/get_all_states"),
    updateStates: (body) => privateInstance.post("/state/update_states", body),
  },

  rcopia: {
    getPatientModeToken: (data) =>
      privateInstance.post("/rcopia/get_patient_mode_token", data),
    getRcopiaTokenByMode: (data) =>
      privateInstance.post("/rcopia/get_rcopia_token_by_mode", data),
    syncPatient: (data) => privateInstance.post("/rcopia/sync_patient", data),
    getAllergies: (data) =>
      privateInstance.post("/rcopia/search_allergies", data),
    getMedications: (data) =>
      privateInstance.post("/rcopia/search_medications", data),
    getNotificationsCount: (data) =>
      privateInstance.get("/rcopia/get_notifications_count", data),
    syncNotificationsCount: (data) =>
      privateInstance.post("/rcopia/sync_notifications_count", data),
  },
  support: {
    contactSupport: (data) =>
      privateInstance.post("/support/contact_support", data),
  },
  file: {
    uploadFile: (data, config = {}) =>
      privateInstance.post("/file/upload_file", data, {
        timeout: 5 * 60 * 1000,
        ...config,
      }),
  },

  getEarnings: (params) => privateInstance.get("/earning/summary", { params }),
  getEarningDetails: (params) =>
    privateInstance.get("/earning/details", { params }),

  getPayrolls: (params) => privateInstance.get("/payroll/summary", { params }),

  getAdminDashboardSummary: (params) =>
    privateInstance.get("/dashboard/admin/summary", { params }),
  getDoctorDashboardSummary: (params) =>
    privateInstance.get("/dashboard/doctor/summary", { params }),
  getPatientDashboardSummary: (params) =>
    privateInstance.get("/dashboard/patient/summary", { params }),
  getPatientSeenDoctors: (params) =>
    privateInstance.get("/dashboard/patient/seen-doctors", { params }),
  getDashboardEncounterDetails: (params) =>
    privateInstance.get("/dashboard/encounters/details", { params }),
};

export default clientApis;
