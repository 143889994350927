import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import clientApis from "../apis";
import useSnackBar from "@shared-ui/components/snackbar";
import { getApiErrorMessage } from "@shared-utils/error-transform";
import noop from "lodash/noop";
import { SINGLETON_CACHE_KEYS, STATE_CACHE_KEYS } from "./cache-keys";

export const useGetOrdersAndActionsConfig = () => {
  return useQuery({
    queryKey: [SINGLETON_CACHE_KEYS.ORDERS_AND_ACTIONS_CONFIG],
    queryFn: () =>
      clientApis.singleton
        .getOrdersAndActionsConfig()
        .then((response) => response?.data?.data || {}),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};

export const useGetOrdersAndActionsInfo = () => {
  const { data } = useGetOrdersAndActionsConfig();
  return {
    videoCallUpgradeEnabled: data?.videoCallUpgradeEnabled,
    labOrderingEnabled: data?.labOrderingEnabled,
    imagingOrderingEnabled: data?.imagingOrderingEnabled,
    workExcuseEnabled: data?.workExcuseEnabled,
    weightLossEnabled: data?.weightLossEnabled,
    aiResponseEnabled: data?.aiResponseEnabled,
    aiSummaryEnabled: data?.aiSummaryEnabled,
  };
};

export const usePracticeInformationGet = () => {
  return useQuery({
    queryKey: [SINGLETON_CACHE_KEYS.PRACTICE_INFORMATION],
    queryFn: () =>
      clientApis.singleton
        .getPracticeInformation()
        .then((response) => response?.data?.data || {}),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};

export const usePracticeInformationInfo = () => {
  const { data } = usePracticeInformationGet();
  return {
    practiceName: data?.practiceName,
    practiceSupportEmail: data?.practiceSupportEmail,
    practiceSupportPhoneNumber: data?.practiceSupportPhoneNumber,
    practiceFaxNumber: data?.practiceFaxNumber,
    policies: data?.policies ?? [],
  };
};

export const useGetLogoAndThemeConfig = () => {
  return useQuery({
    queryKey: [SINGLETON_CACHE_KEYS.LOGOS_AND_THEME_CONFIG],
    queryFn: () =>
      clientApis.singleton
        .getLogosAndThemConfig()
        .then((response) => response?.data?.data || {}),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};

export const useThemeConfig = () => {
  const { data } = useGetLogoAndThemeConfig();
  return {
    themePrimaryColor: data?.themePrimaryColor,
    themeHighlightedColor: data?.themeHighlightedColor,
    providerType: data?.providerType,
  };
};

export const useLogos = () => {
  const { data } = useGetLogoAndThemeConfig();
  return {
    authLogo: data?.authLogo ?? "",
  };
};

const useGetPracticePriceConfig = () => {
  return useQuery({
    queryKey: [SINGLETON_CACHE_KEYS.PRICE_CONFIG],
    queryFn: () =>
      clientApis.singleton
        .getPriceConfig()
        .then((response) => response?.data?.data || {}),
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });
};

export const usePracticePriceInfo = () => {
  const { data, ...rest } = useGetPracticePriceConfig();
  return {
    ...rest,
    encounterPrice: data?.encounterPrice,
    videoCallPrice: data?.videoCallPrice,
  };
};

const useGetOrderTemplatesConfig = () => {
  return useQuery({
    queryKey: [SINGLETON_CACHE_KEYS.ORDER_TEMPLATES_CONFIG],
    queryFn: () =>
      clientApis.singleton
        .getOrderTemplatesConfig()
        .then((response) => response?.data?.data || {}),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};

export const useGetOrderTemplatesInfo = () => {
  const { data, ...rest } = useGetOrderTemplatesConfig();
  return {
    ...rest,
    imagingOrderTemplateUrl: data?.imagingOrderTemplateUrl,
    labOrderTemplateUrl: data?.labOrderTemplateUrl,
    weightLossOrderTemplateUrl: data?.weightLossOrderTemplateUrl,
    workExcuseOrderTemplateUrl: data?.workExcuseOrderTemplateUrl,
  };
};

export const useGetPracticeInformationWithMutation = () => {
  return useMutation({
    mutationFn: async () => {
      const [practiceConfig, states] = await Promise.all([
        clientApis.singleton
          .getPracticeConfig()
          .then((response) => response?.data?.data || {}),
        clientApis.state
          .getAllStates()
          .then((response) => response?.data.data || {}),
      ]);
      return {
        ...practiceConfig,
        states,
      };
    },
  });
};

export const useUpdatePracticeInformation = () => {
  const queryClient = useQueryClient();
  const { notifySuccess, notifyError } = useSnackBar();
  return useMutation({
    mutationFn: async (data) => {
      const { states, pricing, ...rest } = data || {};
      await Promise.all([
        clientApis.singleton.updatePracticeConfig(rest),
        clientApis.state.updateStates(data.states),
      ]);
    },
    onSuccess: () => {
      queryClient
        .invalidateQueries({
          queryKey: [SINGLETON_CACHE_KEYS.LOGOS_AND_THEME_CONFIG],
        })
        .catch(noop);
      queryClient
        .resetQueries({
          queryKey: [SINGLETON_CACHE_KEYS.PRACTICE_INFORMATION],
        })
        .catch(noop);
      queryClient
        .resetQueries({ queryKey: [STATE_CACHE_KEYS.ALL] })
        .catch(noop);
      queryClient
        .resetQueries({ queryKey: [SINGLETON_CACHE_KEYS.PRICE_CONFIG] })
        .catch(noop);
      queryClient
        .resetQueries({
          queryKey: [SINGLETON_CACHE_KEYS.ORDERS_AND_ACTIONS_CONFIG],
        })
        .catch(noop);
      queryClient
        .resetQueries({
          queryKey: [SINGLETON_CACHE_KEYS.ORDER_TEMPLATES_CONFIG],
        })
        .catch(noop);
      notifySuccess("Update successfully");
    },
    onError: (error) => {
      notifyError(getApiErrorMessage(error));
    },
  });
};
