import React, { useCallback, useMemo } from "react";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import useIsCompletedPatientProfile from "../../domain-hooks/use-is-completed-patient-profile";
import { Dialog } from "@shared-ui/components/dialog";
import useToggleModal from "@shared-ui/hooks/use-toggle-modal";
import noop from "lodash/noop";

const Body = ({ open, onClose, onConfirm }) => {
  const navigate = useNavigate();
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs">
      <Dialog.CenteredHeader showIcon onClose={onClose}>
        Fill out your health profile
      </Dialog.CenteredHeader>
      <Dialog.Content>
        <Typography textAlign="center" color="grey">
          Please set up your health profile before purchasing a telemedicine
          encounter.
        </Typography>
      </Dialog.Content>
      <DialogActions sx={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        <Button onClick={onClose} color="grey" variant="outlined" size="large">
          Not Now
        </Button>
        <Button
          color="primary"
          variant="contained"
          size="large"
          component={Link}
          to="/profile"
          onClick={() => {
            navigate("/profile");
            setTimeout(() => {
              onConfirm();
            }, 10);
          }}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const useSetupProfileModal = (onConfirm) => {
  const { open, openModal, closeModal } = useToggleModal();

  const handleConfirm = useCallback(() => {
    closeModal();
    if (onConfirm) {
      onConfirm?.();
    }
  }, [closeModal, onConfirm]);

  const modal = useMemo(
    () => <Body open={open} onClose={closeModal} onConfirm={handleConfirm} />,
    [open, closeModal, handleConfirm],
  );

  return {
    modal,
    openModal,
  };
};

const SetupPatientProfileModal = ({
  to = "/new-encounter",
  onConfirm = noop,
  ...props
}) => {
  const { modal, openModal } = useSetupProfileModal(onConfirm);
  const navigate = useNavigate();
  const { isPending, isCompleted } = useIsCompletedPatientProfile();
  const handleClick = useCallback(() => {
    if (isPending) {
      return;
    }
    if (isCompleted) {
      onConfirm();
      navigate(to);
    } else {
      openModal();
    }
  }, [isCompleted, isPending, navigate, onConfirm, openModal, to]);

  return (
    <>
      <Box onClick={handleClick} {...props} />
      {modal}
    </>
  );
};

export default SetupPatientProfileModal;
