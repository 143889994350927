import React from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import MuiDrawer from "@mui/material/Drawer";

import SidebarFooter from "./SidebarFooter";
import SidebarNav from "./SidebarNav";
import Box from "@mui/material/Box";
import { useLogos } from "@client-services/services/singleton";

const Drawer = styled(MuiDrawer)`
  border-right: 1px solid #e6e8eb;
  background-color: transparent;

  > div {
    box-shadow: none;
  }
`;

const Logo = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0;
  box-shadow: none;
  img {
    object-fit: contain;
  }
`;

const Sidebar = ({ items, onClose, ...rest }) => {
  const { authLogo } = useLogos();
  return (
    <Drawer variant="permanent" onClose={onClose} {...rest}>
      <Box
        px={2}
        display="grid"
        gridTemplateRows="auto 1fr auto"
        height="100%"
        bgcolor="white"
      >
        <Box py={4} mb={2}>
          <Link to="/dashboard" style={{}}>
            <Logo
              alt=""
              src={authLogo}
              style={{
                objectFit: "contain",
                height: "80px",
                borderRadius: "4px",
                maxWidth: "120px",
              }}
            />
          </Link>
        </Box>
        <SidebarNav items={items} onClose={onClose} />
        <SidebarFooter onClose={onClose} />
      </Box>
    </Drawer>
  );
};

export default Sidebar;
