const REACT_APP_ENV = process.env.REACT_APP_ENV;
const REACT_APP_SAAS_NAME = process.env.REACT_APP_SAAS_NAME;

const isLocal = REACT_APP_ENV === "local";
const isDev = REACT_APP_ENV === "dev";
const isStag = REACT_APP_ENV === "stag";
const isProd = REACT_APP_ENV === "prod";

const BASE_CONFIG={
  FILE_MAX_SIZE_IN_MB: 20,
  MAP_API_KEY: "AIzaSyDwzrXKeGX_qbvuAD4_EVHuxBsG-GjOGTs",
}

export const MAHALO_LOCAL = {
  API_URL: "http://localhost:5001",
  GET_STREAM_PUBLIC_API_KEY: "qxgrwwjqh6tt",
  PUSHER_KEY: "17b26a688e134fd948d6",
  PUSHER_CLUSTER: "us3",
  RCOPIA_IFRAME_URL: "https://web.staging.drfirst.com/orca/auth/extInvoke/v10",
  SQUARE_JS_SOURCE: "https://sandbox.web.squarecdn.com/v1/square.js",
  STATIC_FILES_PATH:'https://mahalo-dev-files.s3.us-west-2.amazonaws.com'
};

export const MAHALO_DEV = {
  ...MAHALO_LOCAL,
  API_URL: "https://api.dev.mahalo.clinic",
};

export const MAHALO_STAG = {
  API_URL: "https://api.stag.mahalo.clinic",
  GET_STREAM_PUBLIC_API_KEY: "3xgcgn24kyck",
  PUSHER_KEY: "e130387842499be79d3e",
  PUSHER_CLUSTER: "us3",
  RCOPIA_IFRAME_URL: "https://web.staging.drfirst.com/orca/auth/extInvoke/v10",
  SQUARE_JS_SOURCE: "https://sandbox.web.squarecdn.com/v1/square.js",
  STATIC_FILES_PATH:'https://mahalo-stag-files.s3.us-west-2.amazonaws.com'
};

export const MAHALO_PROD = {
  API_URL: "https://api.app.mahalo.clinic",
  GET_STREAM_PUBLIC_API_KEY: "dtcs76ng856v",
  PUSHER_KEY: "e9c8bcea7232444045f1",
  PUSHER_CLUSTER: "us3",
  RCOPIA_IFRAME_URL: "https://web3.drfirst.com/orca/auth/extInvoke/v10",
  SQUARE_JS_SOURCE: "https://web.squarecdn.com/v1/square.js",
  STATIC_FILES_PATH:'https://mahalo-prod-files.s3.us-west-2.amazonaws.com'
};

const MAHALO_ENV = {
  ...BASE_CONFIG,
  ...(isLocal ? MAHALO_LOCAL : {}),
  ...(isDev ? MAHALO_DEV : {}),
  ...(isStag ? MAHALO_STAG : {}),
  ...(isProd ? MAHALO_PROD : {}),
};

export const ACCESSTELE_PROD = {
  API_URL: "https://api.app.accesstele.health",
  GET_STREAM_PUBLIC_API_KEY: "33xafvzxepkt",
  PUSHER_KEY: "18dd99d45342244f2e87",
  PUSHER_CLUSTER: "us3",
  RCOPIA_IFRAME_URL: "https://web3.drfirst.com/orca/auth/extInvoke/v10",
  SQUARE_JS_SOURCE: "https://web.squarecdn.com/v1/square.js",
  STATIC_FILES_PATH:'https://accesstele-prod-files.s3.us-west-2.amazonaws.com'
};

const ACCESSTELE_ENV = {
  ...BASE_CONFIG,
  ...(isProd ? ACCESSTELE_PROD : {}),
};

const getEnv = () => {
  if (!REACT_APP_SAAS_NAME) {
    throw new Error("REACT_APP_SAAS_NAME required");
  }

  if (REACT_APP_SAAS_NAME === "mahalo") {
    return MAHALO_ENV;
  } else if (REACT_APP_SAAS_NAME === "accesstele") {
    return ACCESSTELE_ENV;
  } else throw new Error("REACT_APP_SAAS_NAME unknown");
};

const CLIENT_ENV = getEnv();
export {CLIENT_ENV}
