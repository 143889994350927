import { useSnackbar as useBaseSnackBar } from "notistack";
import { useCallback } from "react";

export class SnackbarService {
  static enqueueSnackbar = null;
  static closeSnackbar = null;

  static setSnackbar(enqueueSnackbar, closeSnackbarRef) {
    this.enqueueSnackbar = enqueueSnackbar;
    this.closeSnackbar = closeSnackbarRef;
  }

  static notifySuccess(message) {
    if (!this.enqueueSnackbar) {
      return null;
    }
    const key = this.enqueueSnackbar(message, {
      variant: "success",
      onClick: () => {
        this.closeSnackbar?.(key);
      },
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    });
  }

  static notifyError(message) {
    if (!this.enqueueSnackbar) {
      return null;
    }
    const key = this.enqueueSnackbar(message, {
      variant: "error",
      onClick: () => {
        this.closeSnackbar?.(key);
      },
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    });
  }
}

export const useSnackBar = () => {
  const { enqueueSnackbar, closeSnackbar } = useBaseSnackBar();

  const notify = useCallback(
    (message, variant) => {
      const key = enqueueSnackbar(message, {
        variant,
        onClick: () => {
          closeSnackbar(key);
        },
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    },
    [closeSnackbar, enqueueSnackbar],
  );

  const notifySuccess = useCallback(
    (message) => {
      notify(message, "success");
    },
    [notify],
  );

  const notifyError = useCallback(
    (message) => {
      notify(message, "error");
    },
    [notify],
  );

  return {
    notifySuccess,
    notifyError,
  };
};

export default useSnackBar;
