export const setAccessToken = (accessToken) =>
  localStorage.setItem("accessToken", accessToken);
export const setRefreshToken = (refreshToken) =>
  localStorage.setItem("refreshToken", refreshToken);

export const getAccessToken = () => localStorage.getItem("accessToken");
export const getRefreshToken = () => localStorage.getItem("refreshToken");

export const removeAccessToken = () => localStorage.removeItem("accessToken");
export const removeRefreshToken = () => localStorage.removeItem("refreshToken");

export const persistAuthTokens = (accessToken, refreshToken) => {
  setAccessToken(accessToken);
  setRefreshToken(refreshToken);
};

export const removeAuthTokens = (accessToken, refreshToken) => {
  removeAccessToken(accessToken);
  removeRefreshToken(refreshToken);
};

export const getAuthTokens = (accessToken, refreshToken) => {
  return {
    accessToken: getAccessToken(accessToken),
    refreshToken: getRefreshToken(refreshToken),
  };
};

const getBearerToken = () => `Bearer ${getAccessToken()}`;

export const TokenUtils = {
  persistAuthTokens,
  getAuthTokens,
  removeAuthTokens,
  getAccessToken,
  getBearerToken,
  getRefreshToken,
};
