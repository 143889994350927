export const translation = {
  label: "{{providerType}}",
  administration: "{{providerType}} Administration",
  addEditOrRemove: "Add, edit, or remove {{providerType}}",
  edit: "Edit {{providerType}}",
  new: "New {{providerType}}",
  payroll: "{{providerType}} Payroll",
  summary: "{{providerType}} Summary",
  respond: "{{providerType}} Respond",
  leaderboards: "{{providerType}} Leaderboards",
  practiceHasBeenAssignedToYourEncounter:
    "{{providerType}} has been assigned to your encounter",
  tools: "{{providerType}} Tools",
  passwordWillBeReset: `The {{providerType}}'s password will be reset. We will send him an email with a link to reset his password`,
  notFound: "No {{providerType}} found",
  somethingNotWorkingOut:
    "Technical problem? Something not working out with your {{providerType}}?",
  youWillBeReadyToConnectWith: `You will need to purchase the encounter, and once done, you'll be ready to connect with a {{providerType}}.`,
  yourConversationWillTakePlace: `Your conversation with the {{providerType}} will take place in the`,
  keepInMindThatPracticeUsesAnAsynchronousModel: `Keep in mind that {{practiceName}} uses an asynchronous model, meaning the {{providerType}} may take a few hours to respond. Please be patient while waiting for their reply.`,
  waitingFor: "Waiting for {{providerType}}...",
  search: "Search {{providerType}}...",
  youWillSeeThePayrollsHere: "You will see the {{providerType}} payrolls here",
  searchByName: "Search by {{providerType}} name...",
  findingAnAvailableYourConsultation:
    "Finding an available {{providerType}} for your consultation. Thank you for your patience.",
  providerTypeAlertLabel:
    "Please select the option that best describes your practice setup. If you have a single physician, choose '{{providerType}}.' If you have multiple types of healthcare professionals, choose  '{{providerType}} and mid-levels'.",
  admin: "{{providerType}} Admin",
  signOutOfPractice: "Sign out of {{practiceName}}",
  rankingOfPracticePatients: "Rankings of {{practiceName}} patients",
  rankingOfPracticeProviderType:
    "Rankings of {{practiceName}} {{providerType}}s",
  trackPracticePerformance:
    "Track {{practiceName}}'s performance across numerous metrics",
  patientGuideToPracticeClinic: "Patient's guide to {{practiceName}}",
  providerTypeGuideToPracticeClinic:
    "{{providerType}}'s Guide to {{practiceName}}",
  signBackInToPractice: "Sign Back in to {{practiceName}}",
  notifyMeEachTimeNewUserSignUp:
    "Notify me each time a new user signs up to {{practiceName}}",
  checkYourBoxForAnEmail:
    "Check your inbox for an email from {{practiceName}}.",
  onceVerifiedYouCanLogIn:
    "Once verified, you can log in and start using {{practiceName}}'s patient portal.",
  twentyFourSevenAccessToPractice:
    "24/7 Access to {{practiceName}}'s {{providerType}}s",
  welcomeToPractice: "Welcome to {{practiceName}}",
  secureCheckout: "Secure Checkout - {{practiceName}} Encounter Purchase",
  thankYouForChoosingPractice:
    "Thank you for choosing {{practiceName}} for your urgent care needs. Your encounter has been successfully created and submitted to our dedicated team.",
  wantingMore:
    "Wanting more? Get better value with {{practiceName}} Membership!",
  joinPractice: "Join {{practiceName}}",
  gettingStartedWithPractice:
    "Getting Started with {{practiceName}} Telemedicine",
  welcomeToSimpleGuide:
    "Welcome to {{practiceName}}! Here's a simple guide to help you get started with your telemedicine experience:",
  enjoyConvenienceOfPractice:
    "Enjoy the convenience of {{practiceName}}'s 24/7 telemedicine service!",
  gettingStartedGuide:
    "Getting Started Guide for {{providerType}}s on {{practiceName}} Telemedicine",
  welcomeToPracticeHowToGetStarted:
    "Welcome to {{practiceName}}! Here's how to get started seeing patients on the platform:",
  rememberPracticeOperatesAsynchronously:
    "Remember, {{practiceName}} operates asynchronously, so be sure to regularly check back for patient responses and provide timely follow-ups.",
  thankYouForBeingAPart: "Thank you for being part of {{practiceName}}!",
  urgentCareSpecialist: "{{practiceName}}",
  generatingImagingStudies:
    "Generating imaging studies orders will issue a PDF to your patient with their order details. It is the patient's responsibility to present this order at their selected imaging center. Any imaging results sent to {{practiceName}} by the imaging center will automatically be uploaded to the patient's account, with notifications sent to you. Critical results will be called to you.",
  practiceIsNotLiableForImaging:
    "Note that {{practiceName}} is not liable for unperformed patient imaging or imaging studies not transmitted or otherwise reported to us by the imaging center. {{practiceName}} is also not responsible for reaching out to imaging centers to obtain results.",
  generatingLaboratoryStudies:
    "Generating laboratory studies orders will issue a PDF to your patient with their order details. It is the patient's responsibility to present this order at their selected lab draw station. Any lab results sent to {{practiceName}} by the lab will automatically be uploaded to the patient's account, with notifications sent to you. Critical results will be called to you.",
  practiceIsNotLiableForLabs:
    "Note that {{practiceName}} is not liable for unperformed patient labs or labs not reported to us. {{practiceName}} is also not responsible for reaching out to laboratories to obtain results.",
  practiceSuggestedResponse: "{{practiceName}} Suggested Response",
  practiceSummary: "{{practiceName}} Summary",
  practiceIsExclusivelyForStates:
    "{{practiceName}} is exclusively for patients physically in {{stateNames}}, so please ensure your preferred pharmacy is located within the state.",
  thisPrescriptionIsForInjectableSemaglutide:
    "This prescription is for injectable semaglutide. {{practiceName}} will submit this prescription to Olympia Pharmacy on your behalf. Olympia Pharmacy will contact the patient directly to arrange payment and shipping details.",
  thisPrescriptionIsForInjectableTirzepatide:
    "This prescription is for injectable tirzepatide. {{practiceName}} will submit this prescription to Olympia Pharmacy on your behalf. Olympia Pharmacy will contact the patient directly to arrange payment and shipping details.",
  iAgreeToReceiveSms:
    "I agree to receive SMS text messages from {{practiceName}} regarding my encounters, health services, and updates. Message and data rates may apply. Reply 'STOP' to opt out at any time.",
  iUnderstandThatPracticeIsExclusively:
    "I understand that {{practiceName}} is exclusively for patients physically in {{stateNames}}, and I will only seek healthcare through the platform while in the state.",
  providerTypeName: "{{providerType}} Name",
  connectWithProviderTypeNow: "Connect with a {{providerType}} Now",
  converseWithProviderType: "Converse with the {{providerType}}",
};
