import { useMutation } from "@tanstack/react-query";
import { getApiErrorMessage } from "@shared-utils/error-transform";
import useSnackBar from "@shared-ui/components/snackbar";
import clientApis from "@client-services/apis";
import { base64ToBlob } from "@shared-utils/file";
import { compressImage } from "../../util";

export const uploadClientFileOrBlob = async (
  file,
  { prefix = "", onUploadProgress } = {},
) => {
  const formData = new FormData();
  const compressedFile = await compressImage(file);
  formData.append("file", compressedFile);
  formData.append("prefix", prefix);
  const { url, size, mimetype } = await clientApis.file
    .uploadFile(formData, { onUploadProgress })
    .then((response) => response?.data?.data ?? {});
  return { url, size, mimetype };
};

const uploadClientBase64File = async (base64) => {
  const mimeType = "image/png";
  const blob = base64ToBlob(base64.split(",")[1], mimeType);
  return uploadClientFileOrBlob(blob);
};

export const useUploadSignatureImage = () => {
  const { notifyError } = useSnackBar();
  return useMutation({
    mutationFn: (base64) => uploadClientBase64File(base64),
    onError: (error) => {
      notifyError(getApiErrorMessage(error));
    },
  });
};
