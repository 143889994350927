import { ReactComponent as DashboardIcon } from "./icons/grid.svg";
import { ReactComponent as MailIcon } from "./icons/mail.svg";
import { ReactComponent as PlusIcon } from "./icons/plus-circle.svg";
import { ReactComponent as HealthHistoryIcon } from "./icons/health-history.svg";
import { ReactComponent as ClockIcon } from "./icons/clock.svg";
import { ReactComponent as EarningsIcon } from "./icons/database.svg";

import { SYSTEM_ROLE_KEYS } from "@shared-domain/constants/system";
import WaitingRoomBadge from "./badges/waiting-room-badge";
import SidebarNavListItem from "./SidebarNavListItem";
import SetupPatientProfileModal from "../../domain/domain-components/setup-patient-profile-modal";

const NewEncounterSideBarItem = (props) => {
  return (
    <SetupPatientProfileModal to={props.href} onConfirm={props.onClose}>
      <SidebarNavListItem
        {...props}
        onMenuItemPress={(event) => event.preventDefault()}
      />
    </SetupPatientProfileModal>
  );
};

const EncountersSideBarItem = (props) => {
  return (
    <SetupPatientProfileModal to={props.href} onConfirm={props.onClose}>
      <SidebarNavListItem
        {...props}
        onMenuItemPress={(event) => event.preventDefault()}
      />
    </SetupPatientProfileModal>
  );
};

const navItems = [
  {
    id: "1",
    href: "/dashboard",
    Icon: <DashboardIcon />,
    title: "Dashboard",
    roles: [
      SYSTEM_ROLE_KEYS.ADMIN,
      SYSTEM_ROLE_KEYS.SUPPORT,
      SYSTEM_ROLE_KEYS.DOCTOR,
      SYSTEM_ROLE_KEYS.PATIENT,
    ],
    closeOnSelect: true,
  },
  {
    id: "2",
    href: "/waiting-room",
    title: "Waiting Room",
    roles: [
      SYSTEM_ROLE_KEYS.ADMIN,
      SYSTEM_ROLE_KEYS.SUPPORT,
      SYSTEM_ROLE_KEYS.DOCTOR,
    ],
    Icon: (
      <WaitingRoomBadge>
        <ClockIcon />
      </WaitingRoomBadge>
    ),
    closeOnSelect: true,
  },
  {
    id: "3",
    href: "/new-encounter",
    Icon: <PlusIcon />,
    title: "New Encounter",
    roles: [SYSTEM_ROLE_KEYS.PATIENT],
    SidebarNavListItem: NewEncounterSideBarItem,
    closeOnSelect: false,
  },
  {
    id: "4",
    href: "/encounters",
    Icon: <MailIcon />,
    title: "Encounters",
    roles: [SYSTEM_ROLE_KEYS.PATIENT],
    SidebarNavListItem: EncountersSideBarItem,
    closeOnSelect: false,
  },
  {
    id: "5",
    href: "/encounters",
    Icon: <MailIcon />,
    title: "Encounters",
    roles: [
      SYSTEM_ROLE_KEYS.ADMIN,
      SYSTEM_ROLE_KEYS.SUPPORT,
      SYSTEM_ROLE_KEYS.DOCTOR,
    ],
    closeOnSelect: true,
  },
  {
    id: "6",
    href: "/profile",
    Icon: <HealthHistoryIcon />,
    title: "Health History",
    roles: [SYSTEM_ROLE_KEYS.PATIENT],
    closeOnSelect: true,
  },
  {
    id: "7",
    href: "/rapid-responses",
    Icon: <HealthHistoryIcon />,
    title: "Rapid Response",
    roles: [
      SYSTEM_ROLE_KEYS.ADMIN,
      SYSTEM_ROLE_KEYS.SUPPORT,
      SYSTEM_ROLE_KEYS.DOCTOR,
    ],
    closeOnSelect: true,
  },
  {
    id: "8",
    href: "/earnings",
    Icon: <EarningsIcon />,
    title: "Earnings",
    roles: [
      SYSTEM_ROLE_KEYS.ADMIN,
      SYSTEM_ROLE_KEYS.SUPPORT,
      SYSTEM_ROLE_KEYS.DOCTOR,
    ],
    closeOnSelect: true,
  },
];

export default navItems;
