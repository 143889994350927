export const SYSTEM_ROLE_KEYS = {
  PATIENT: "Patient",
  DOCTOR: "Doctor",
  SUPPORT: "Support",
  ADMIN: "Admin",
  SUPER_ADMIN: "SuperAdmin",
};

export const CHAT_ROLE_KEYS = {
  PATIENT: "user",
  DOCTOR: "moderator",
  SUPPORT: "admin",
  ADMIN: "admin",
};

export const APPOINTMENT_STATUS_KEYS = {
  ALL: "all",
  UNASSIGNED: 0,
  ASSIGNED: 1,
  CLOSED: 2,
  REOPENED: 3,
  REFUND: 4,
};

export const PROFILE_SETUP_STATUS_KEYS = {
  COMPLETED: "completed",
};

export const ACCOUNT_STATUS_KEYS = {
  ALL: "all",
  ACTIVE: "active",
  SUSPENDED: "suspended",
  SUSPENDED_DUE_TO_CREDENTIALS: "suspended-due-to-credentials",
  SUSPENDED_DUE_TO_PERFORMANCE: "suspended-due-to-performance",
};

export const ACCOUNT_STATUS_KEYS_TO_LABEL_MAP = {
  [ACCOUNT_STATUS_KEYS.ACTIVE]: "Active",
  [ACCOUNT_STATUS_KEYS.SUSPENDED]: "Suspended",
  [ACCOUNT_STATUS_KEYS.SUSPENDED_DUE_TO_PERFORMANCE]: "Performance",
  [ACCOUNT_STATUS_KEYS.SUSPENDED_DUE_TO_CREDENTIALS]: "Credentials",
};

export const EncounterNoteTypes = {
  ENCOUNTER_CLOSED_NOTE: "encounter-closed",
  ENCOUNTER_REFUNDED_NOTE: "encounter-refunded",
  REGULAR_NOTE: "regular-note",
  PRESCRIPTION: "prescription",
  IMAGING_ORDER: "imaging-order",
  LAB_ORDER: "lab-order",
  WORK_EXCUSE_ORDER: "work-excuse-order",
  WEIGHT_LOSS_MEDICATION_ORDER: "weight-loss-medication-order",
  LAB_ORDER_RESULT: "lab-order-result",
  IMAGING_ORDER_RESULT: "imaging-order-result",
};

export const SystemTypes = {
  OWN: "own",
  RCOPIA: "rcopia",
};

export const PRICING_TYPES = {
  ENCOUNTER_PURCHASED: "encounter-one-time",
  ENCOUNTER_VIDEO_CALL: "encounter-video-call",
};

export const ADD_SUBCATEGORY_CONSENT_KEYS = {
  WITH_SUBCATEGORIES: "with-subcategories",
  WITHOUT_SUBCATEGORIES: "without-subcategories",
};

export const POLICY_TYPE_KEYS = {
  TERMS_OF_USE: "terms_of_use",
  CONSENT: "consent",
  PRIVACY_POLICY: "privacy_policy",
  ONBOARDING_PROCESS_POLICY: "onboarding_process_policy",
  QUALITY_ASSURANCE_MONITORING_POLICY: "quality_assurance_monitoring_policy",
  LICENSURE_AND_CERTIFICATION_MAINTENANCE_POLICY:
    "licensure_and_certification_maintenance_policy",
  PHYSICIAN_GRIEVANCE_POLICY: "physician_grievance_policy",
};
