import imageCompression from "browser-image-compression";

export const compressImage = async (file) => {
  if (!file.type.startsWith("image/") || file.size <= 1024 * 1024) {
    return file;
  }
  try {
    return await imageCompression(file, {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    });
  } catch (error) {
    return file;
  }
};
