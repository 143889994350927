import React, { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { CacheProvider } from "@emotion/react";

import { ThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { SnackbarProvider, useSnackbar } from "notistack";
import createTheme from "../../shared-ui/theme";
import { buildRoutes } from "./routes";
import useRoles from "@shared-features-auth/use-roles";
import createCache from "@emotion/cache";
import { SnackbarService } from "@shared-ui/components/snackbar";
import {
  useLogos,
  usePracticeInformationInfo,
  useThemeConfig,
} from "@client-services/services/singleton";
import { Helmet } from "react-helmet";
import { CLIENT_ENV } from "@shared-utils/envs/client";

import { GlobalConfigProvider } from "@shared-domain/context/global-context";
import { uploadClientFileOrBlob } from "@client-services/services/files";
import { ClientAuthInjector } from "@shared-features-auth/client-auth-injector";

const clientSideEmotionCache = createCache({ key: "css" });

const SnackBarInit = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  useEffect(() => {
    SnackbarService.setSnackbar(enqueueSnackbar, closeSnackbar);
  }, [closeSnackbar, enqueueSnackbar]);
  return null;
};

const Header = () => {
  const { authLogo } = useLogos();
  const { practiceName } = usePracticeInformationInfo();
  return (
    <>
      <Helmet>
        {authLogo && <link rel="icon" href={authLogo} />}
        <title>{practiceName}</title>
        <script type="text/javascript" src={CLIENT_ENV.SQUARE_JS_SOURCE} />
      </Helmet>
    </>
  );
};

const Routes = () => {
  const { isDoctor, isPatient } = useRoles();
  const routes = buildRoutes({ isDoctor, isPatient });
  const router = createBrowserRouter(routes);
  return <RouterProvider router={router} />;
};

function App({ emotionCache = clientSideEmotionCache }) {
  const { themePrimaryColor, themeHighlightedColor, providerType } =
    useThemeConfig();
  const { practiceName } = usePracticeInformationInfo();
  return (
    <GlobalConfigProvider
      practiceName={practiceName}
      providerType={providerType}
      themeHighlightedColor={themeHighlightedColor}
      uploadFileOrBlob={uploadClientFileOrBlob}
      env={CLIENT_ENV}
    >
      <Header />
      <CacheProvider value={emotionCache}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ThemeProvider theme={createTheme(themePrimaryColor)}>
            <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
              <SnackBarInit />
              <ClientAuthInjector>
                <Routes />
              </ClientAuthInjector>
            </SnackbarProvider>
          </ThemeProvider>
        </LocalizationProvider>
      </CacheProvider>
    </GlobalConfigProvider>
  );
}

export default App;
