import axios from "axios";
import { TokenUtils } from "@shared-utils/storage";
import ResponseInterceptors from "./interceptors";

export const buildApiInstances = (baseURL, queryClient) => {
  const privateInstance = axios.create({
    baseURL,
    timeout: 60000,
  });

  const publicInstance = axios.create({
    baseURL,
    timeout: 60000,
  });

  privateInstance.interceptors.request.use(async function (config) {
    config.headers.Authorization = `Bearer ${TokenUtils.getAccessToken()}`;
    return config;
  });

  const interceptors = new ResponseInterceptors(baseURL, queryClient);
  privateInstance.interceptors.response.use(
    interceptors.onFulfilled,
    interceptors.onRejected,
  );

  return {
    publicInstance,
    privateInstance,
  };
};
