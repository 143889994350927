import { useGetMe } from "@client-services/services/user";

import { PROFILE_SETUP_STATUS_KEYS } from "@shared-domain/constants/system";

const useIsCompletedPatientProfile = () => {
  const {
    data = {},
    isLoading,
    isSuccess,
    isError,
    error,
    isPending,
  } = useGetMe();
  return {
    isPending,
    isLoading,
    isSuccess,
    isError,
    error,
    isCompleted: data.profileStatus === PROFILE_SETUP_STATUS_KEYS.COMPLETED,
  };
};

export default useIsCompletedPatientProfile;
