import App from "./app/app";
import ReactDOM from "react-dom/client";

import { QueryClientProvider } from "@tanstack/react-query";
import "@shared-ui/localisation/index";

import { clientQueryClient } from "@client-services/apis/client";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <QueryClientProvider client={clientQueryClient}>
    <App />
  </QueryClientProvider>,
);
